import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import exportFromJSON from 'export-from-json'
import configData from "../../configuration/config.json";
import Navbar from '../../../src/components/navbar/index';
import { redirect, useNavigate } from 'react-router-dom';


function ReactComponent() {
  const navigate = useNavigate();
  const [yearsArray, setYearsArray] = useState(null);
  const [studentsArray, setStudentsArray] = useState(null);

  useEffect(() => {
    getYears();
  }, []);


  async function getYears() {

    const requestOptions = {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken') },
    };

    let res = await fetch(configData.api_url + "/tms/api/years", requestOptions);
    if (res.status == 403) {
      navigate('/');
    }
    let json = await res.json();
    setYearsArray(json.Items);
  }


  async function getStudents() {
    showLoader();
    const requestOptions = {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken') },
    };

    let yearid = document.getElementById('ddlyear').value;

    let res = await fetch(configData.api_url + "/tms/api/students?yearid=" + yearid, requestOptions);

    if (res.status == 403) {
      navigate('/');
    }

    let json = await res.json();
    setStudentsArray(json.Items);
    
    closeLoader();
  }


  function showLoader() {
    document.getElementById('linearloaderdiv').style.display = 'block';
  }

  function closeLoader() {
    document.getElementById('linearloaderdiv').style.display = 'none';
  }

  function viewStudent(stu) {
    let studentID = stu.currentTarget.id.split('_')[1];
    
    navigate('/students-view/' + studentID);
  }


  function createNewStudent(){
    navigate('/students-view');
  }

  async function deleteStudent(stu) {

    let deleteConfirmation = window.confirm('Are you sure to delete the Student record?.')

    if (deleteConfirmation) {

      showLoader();
      const requestOptions = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken')
        },

      };

      let studentId = stu.target.id.split('_')[1];

      const response = await fetch(configData.api_url + "/tms/api/students/" + studentId, requestOptions);

      if (response.status == 403) {
        navigate('/');
      }

      if (response.status == 200) {
        getStudents();
        toast.success("Deleted Successfully");
      }
      closeLoader();
    }

  }

  return (
    <Container>
      <Row>
        <Col>
          <Navbar></Navbar>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col><h2>Students</h2></Col>
        <Col className="d-flex justify-content-end"><button onClick={createNewStudent} type="button" id='btnNewStudent' className="btn btn-secondary">New Student</button></Col>
      </Row>

<Row>
  <Col sm={4}>
  <div><span>Year</span></div>
<div><select className='w-100' id='ddlyear'>
          <option key='0' value='0'></option>
          {yearsArray != undefined && yearsArray.map((arrayItem) => <option key={arrayItem.id['N']} value={arrayItem.id['N']}>{arrayItem.name['S']}</option>)}
        </select></div>
  </Col>
</Row>

    

      <Row className='mt-2'>
        <Col sm>
          <button type="button" onClick={getStudents} className="btn btn-primary">Search</button>
        </Col>
      </Row>

      {
                studentsArray != undefined ?
                  <>
                    {

      <Row>
        <Col>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">First Name</th>
                <th scope="col">Sur Name</th>
                <th scope="col">Year</th>
                <th scope="col">Subjects</th>
                <th scope="col">Parent Contact</th>
                <th scope="col">Student Contact</th>
                <th scope="col">Email</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>

              {
                studentsArray != undefined &&

                studentsArray.map((arrayItem, index) =>
                  <>
                    {
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{arrayItem.firstname['S']}</td>
                        <td>{arrayItem.surname['S']}</td>
                        <td>{arrayItem.yearid['N']}</td>
                        <td>{arrayItem.subjectsNames}</td>
                        <td>{arrayItem.parentcontactno['S']}</td>
                        <td>{arrayItem.studentcontactno['S']}</td>
                        <td>{arrayItem.email['S']}</td>
                        <td>
                          <div>
                            <button type="button" id={'btnedit_' + arrayItem.id['N']} onClick={viewStudent} className="btn btn-primary">Edit</button>{' '}
                            <button type="button" id={'btndelete_' + arrayItem.id['N']} onClick={deleteStudent} className="btn btn-primary">Delete</button>
                          </div>
                        </td>
                      </tr>

                    }
                  </>




                )

              }




            </tbody>
          </table>

        </Col>

      </Row>

            }
</> : ''
                
            }
    </Container>

  );
}

export default ReactComponent;