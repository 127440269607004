import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import exportFromJSON from 'export-from-json'
import configData from "../../configuration/config.json";
import Navbar from '../../../src/components/navbar/index';
import { redirect, useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

function ReactComponent() {
  const navigate = useNavigate();
  const [yearsArray, setYearsArray] = useState(null);
  const [subjectArray, setSubjectArray] = useState(null);
  const [feesArray, setfeesArray] = useState(null);

  useEffect(() => {
    getYears();
  }, []);


  async function getYears() {

    const requestOptions = {
      headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken') },
    };

    let res = await fetch(configData.api_url + "/tms/api/years", requestOptions);
    if (res.status == 403) {
      navigate('/');
    }
    let json = await res.json();
    setYearsArray(json.Items);
  }


  function showLoader() {
    document.getElementById('linearloaderdiv').style.display = 'block';
  }

  function closeLoader() {
    document.getElementById('linearloaderdiv').style.display = 'none';
  }

  async function getSubjects() {

    setSubjectArray(null);
    showLoader();

    const requestOptions = {
        headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken') },
    };

    let ddlyear = document.getElementById('ddlyear').value;

    let res = await fetch(configData.api_url+"/tms/api/subjects/years/"+ddlyear, requestOptions);

    if(res.status == 403){
        navigate('/');
    }

    let json = await res.json();
    setSubjectArray(json.Items);

    closeLoader();
}


async function getFees() {

    setfeesArray(null);
    showLoader();

    const requestOptions = {
        headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken') },
    };

    let ddlyear = document.getElementById('ddlyear').value;

    let res = await fetch(configData.api_url+"/tms/api/fees?yearid="+ddlyear, requestOptions);

    if(res.status == 403){
        navigate('/');
    }

    let json = await res.json();

    if(json.length == 0){
        toast.success("No data found");
    }else{
        setfeesArray(json);
    }
    

    closeLoader();
}


  return (
      <Container>
          <Row>
              <Col>
                  <Navbar></Navbar>
              </Col>
          </Row>

          <Row className='mt-3'>
        <Col><h2>Fees</h2></Col>
      </Row>


          <Row>
          <Col sm={4}>
                  <div><span>Year</span></div>
                  <div><select className='w-100' id='ddlyear'>
                      <option key='0' value='0'></option>
                      {yearsArray != undefined && yearsArray.map((arrayItem) => <option key={arrayItem.id['N']} value={arrayItem.id['N']}>{arrayItem.name['S']}</option>)}
                  </select></div>
              </Col>
          </Row>

          <Row className='mt-2'>
          <Col sm={4}>
                  <button type="button" onClick={getFees}  className="btn btn-primary">Search Fees</button>
              </Col>
          </Row>

          <Row className='mt-2'>
          <Col sm={12}>
                    <div>
                        <Row>
                      
                            {
                                feesArray != undefined &&
                                feesArray.map((arrayItem, index) =>

                                    <>
                                  
                                        {
                                               <Col sm={4} className='p-2'>
                                              <div>
                                              <Card className='text-nowrap'>
      <Card.Header>{arrayItem.subjects}</Card.Header>
      <Card.Body>
        <Card.Title>{"£"+arrayItem.fees}</Card.Title>
      </Card.Body>
    </Card>
    </div>
                                           
    </Col>
                                        }
                                        
                                    </>
                                )



                            }

                        
                        </Row>
                    </div>

                </Col>

            </Row>

      </Container>

  );
}

export default ReactComponent;