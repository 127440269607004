import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configData from "../../configuration/config.json";
import Navbar from '../../../src/components/navbar/index';
import { useNavigate } from 'react-router-dom';


function ReactComponent() {
    const navigate = useNavigate();
    const [yearsArray, setYearsArray] = useState(null);
    const [subjectArray, setSubjectArray] = useState(null);
    const [tutorArray, setTutorArray] = useState(null);
    const [studentsArray, setStudentsArray] = useState(null);
    const [attendanceRecordId, setAttendanceRecordId] = useState(0);
   
    let userType =  sessionStorage.getItem('type');
    let tutorID =  sessionStorage.getItem('userid');

    useEffect(() => {
        showLoader();
        getYears();
        getTutors();
        closeLoader();
    }, []);


    async function getYears() {
        
        const requestOptions = {
            headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken') },
        };

        let res = await fetch(configData.api_url+"/tms/api/years", requestOptions);

        if(res.status == 403){
            navigate('/');
        }

        let json = await res.json();
        setYearsArray(json.Items);
    }

    async function getSubjects() {

        const requestOptions = {
            headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken') },
        };

        let ddlyear = document.getElementById('ddlyear').value;

        let res = await fetch(configData.api_url+"/tms/api/subjects/years/"+ddlyear, requestOptions);

        if(res.status == 403){
            navigate('/');
        }

        let json = await res.json();
        setSubjectArray(json.Items);

        closeLoader();
    }

    async function getTutors() {

        const requestOptions = {
            headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken') },
        };

      

        let tutorsAPI = configData.api_url+"/tms/api/tutors";

        if(userType  == 'TUTOR'){
            tutorsAPI = configData.api_url+"/tms/api/tutors/"+tutorID;
        }

        let res = await fetch(tutorsAPI, requestOptions);

        if(res.status == 403){
            navigate('/');
        }

        let json = await res.json();
        setTutorArray(json.Items);
    }


    async function getStudents() {

        setStudentsArray(null);

        if (document.getElementById('txtDate').value == ''
            || document.getElementById('txtDate').value == undefined
            || document.getElementById('ddlsubject').value == '0'
            || document.getElementById('ddltutor').value == '0'
            || document.getElementById('ddlyear').value == '0'
        ) {
            toast.error("All fields are mandatory");
            return;
        }

        showLoader();

        let attendancedate = Date.parse(document.getElementById('txtDate').value).toString();
        let ddlyear = document.getElementById('ddlyear').value;
        let subjectid = document.getElementById('ddlsubject').value;
        let tutorid = document.getElementById('ddltutor').value;

        const requestOptions = {
            headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken') },
        };


        let existingAttendanceResponse = await fetch(configData.api_url+"/tms/api/attendance?"
            + 'yearid=' + ddlyear.toString() + '&'
            + 'attendancedate=' + attendancedate.toString() + '&'
            + 'subjectid=' + subjectid.toString() + '&'
            + 'tutorid=' + tutorid.toString() + '&'
        , requestOptions);

        if(existingAttendanceResponse.status == 403){
            navigate('/');
        }

        let existingJson = await existingAttendanceResponse.json();
        
        if (existingJson != null && existingJson.studentsMaster.Count > 0) {
            setAttendanceRecordId(existingJson.attendanceRecordId)
            setStudentsArray(existingJson.studentsMaster.Items);
        } else {
            setStudentsArray(null);
            toast.info('No records found');
        } 
        closeLoader();
    }

    async function postAttendance() {



        if (studentsArray == null || studentsArray.count == 0) {
            toast.error("All fields are mandatory");
            return;
        }

showLoader();

        let studentsPresentArray = [];
        let studentsEntryTrue = 0;

        studentsArray.map((arrayItem, index) => {


            let studentRecord = {
                M: {}
             };

            studentRecord.M.id = { "N": arrayItem.id['N'] };

           
          
            

            if (document.getElementById("flexCheckDefault_" + arrayItem.id['N']).checked) {
                studentRecord.M.attendance = { "N": '1' };
                studentsEntryTrue = studentsEntryTrue + 1;
            } else {
                studentRecord.M.attendance = { "N": '0' };
            }
            

            if (document.getElementById("hwsubmittedCheckDefault_" + arrayItem.id['N']).checked) {
                studentRecord.M.homework = { "N": '1' };
                studentsEntryTrue = studentsEntryTrue + 1;
            } else {
                studentRecord.M.homework = { "N": '0' };
            }

            let enteredMark = document.getElementById("marks_" + arrayItem.id['N']).value;
            enteredMark = enteredMark == '' ? '-1' : enteredMark;
            studentRecord.M.marks = { "N": enteredMark };
            studentsEntryTrue = enteredMark != '-1' ? studentsEntryTrue + 1 : studentsEntryTrue;

            let enteredOutstanding = document.getElementById("outstanding_" + arrayItem.id['N']).value;
            enteredOutstanding = enteredOutstanding == '' ? '-1' : enteredOutstanding;
            studentRecord.M.outstandingbalance = { "N": enteredOutstanding };
            studentsEntryTrue = enteredOutstanding != '-1' ? studentsEntryTrue + 1 : studentsEntryTrue;
           
            studentsPresentArray.push(studentRecord);

        });
        
        if (document.getElementById('txtDate').value == ''
            || document.getElementById('txtDate').value == undefined
            || document.getElementById('ddlsubject').value == '0'
            || document.getElementById('ddltutor').value == '0'
            || document.getElementById('ddlyear').value == '0'
            
        ) {
            closeLoader();
            toast.error("All fields are mandatory");
            return;
        }

        if ((studentsEntryTrue == 0 || studentsPresentArray.length == 0)
        && attendanceRecordId == 0)
        {
            closeLoader();
            toast.error("Student information not filled");
            return;
        }

        if ((studentsEntryTrue == 0 || studentsPresentArray.length == 0)
            && attendanceRecordId != 0) {
                closeLoader();
            toast.error("Student information not entered. Choose the DELETE option to remove all details");
            return;
        }

        let createdOrModified = '';
        let createdOrModifiedValue = {"N": sessionStorage.getItem("userid")};

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken')
        },
            body: JSON.stringify({
                "TableName": "attendance_dtl",
                "Item": {
                    "attendancedate": { "N": Date.parse(document.getElementById('txtDate').value).toString()  },
                    "students": { "L": studentsPresentArray },
                    "subject_id": { "N": document.getElementById('ddlsubject').value },
                    "tutor_id": { "N": document.getElementById('ddltutor').value },
                    "year_id": { "N": document.getElementById('ddlyear').value },
                    "id": { "N": attendanceRecordId },
                    "createdby": createdOrModifiedValue,
                    "modifiedby": createdOrModifiedValue,
                }
            })
        };
        const response = await fetch(configData.api_url+"/tms/api/attendance", requestOptions);

        if(response.status == 403){
            navigate('/');
        }

        if (response.status == 200) {
            toast.success("Posted Successfully");
            clearControls();
        }
        closeLoader();
    }


    async function putAttendance() {



        if (studentsArray == null || studentsArray.count == 0) {
            toast.error("All fields are mandatory");
            return;
        }

        showLoader();

        let studentsPresentArray = [];
        let studentsEntryTrue = 0;

        studentsArray.map((arrayItem, index) => {


            let studentRecord = {
             };

            studentRecord.id = Number.parseInt(arrayItem.id['N']);

            if (document.getElementById("flexCheckDefault_" + arrayItem.id['N']).checked) {
                studentRecord.attendance = 1;
                studentsEntryTrue = studentsEntryTrue + 1;
            } else {
                studentRecord.attendance = 0;
            }
            

            if (document.getElementById("hwsubmittedCheckDefault_" + arrayItem.id['N']).checked) {
                studentRecord.homework =1;
                studentsEntryTrue = studentsEntryTrue + 1;
            } else {
                studentRecord.homework = 0;
            }

            let enteredMark = document.getElementById("marks_" + arrayItem.id['N']).value;
            enteredMark = enteredMark == '' ? -1 : Number.parseInt(enteredMark);
            studentRecord.marks = enteredMark;
            studentsEntryTrue = enteredMark != '-1' ? studentsEntryTrue + 1 : studentsEntryTrue;

            let enteredOutstanding = document.getElementById("outstanding_" + arrayItem.id['N']).value;
            enteredOutstanding = enteredOutstanding == '' ? -1 : Number.parseInt(enteredOutstanding);
            studentRecord.outstandingbalance = enteredOutstanding;
            studentsEntryTrue = enteredOutstanding != '-1' ? studentsEntryTrue + 1 : studentsEntryTrue;
           
            studentsPresentArray.push(studentRecord);

        });
        
        if (document.getElementById('txtDate').value == ''
            || document.getElementById('txtDate').value == undefined
            || document.getElementById('ddlsubject').value == '0'
            || document.getElementById('ddltutor').value == '0'
            || document.getElementById('ddlyear').value == '0'
            
        ) {
            closeLoader();
            toast.error("All fields are mandatory");
            return;
        }

        if ((studentsEntryTrue == 0 || studentsPresentArray.length == 0)
        && attendanceRecordId == 0)
        {
            closeLoader();
            toast.error("Student information not filled");
            return;
        }

        if ((studentsEntryTrue == 0 || studentsPresentArray.length == 0)
            && attendanceRecordId != 0) {
                closeLoader();
            toast.error("Student information not entered. Choose the DELETE option to remove all details");
            return;
        }

        let createdOrModifiedValue = isNaN(sessionStorage.getItem("userid")) == false ? 
        parseInt(sessionStorage.getItem("userid")) : 0;

        var params = {
            TableName: 'attendance_dtl',
            Key: {
                "id": Number.parseInt(attendanceRecordId)
            },
            UpdateExpression: 'set students = :t, modifiedby = :s',
            ExpressionAttributeValues: {
              ':t' : studentsPresentArray,
              ':s':  createdOrModifiedValue
            }
          };


        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken')
        },
            body: JSON.stringify(params)
        };
        const response = await fetch(configData.api_url+"/tms/api/attendance", requestOptions);
        
        if(response.status == 403){
            navigate('/');
        }

        if (response.status == 200) {
            toast.success("Updated Successfully");
            clearControls();
        }else{
            toast.error(response.status + '-'+ response.statusText);
        }
        closeLoader();
    }

    function clearControls() {
        document.getElementById('txtDate').value = '';
        document.getElementById('ddlsubject').value = '0';
        document.getElementById('ddltutor').value = '0';
        document.getElementById('ddlyear').value = '0';
        setStudentsArray(null);
        setAttendanceRecordId(0);
    }

    function clearStudents() {
        setStudentsArray(null);
        setAttendanceRecordId(0);
    }

    function changeYear(){
        setSubjectArray(null);
        showLoader();
        clearStudents();
        getSubjects();
    }

    async function deleteAttendance() {
        showLoader();
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken')
        },
            body: JSON.stringify({
                "TableName": "attendance_dtl",
                "Key": {
                    "id": { "N": attendanceRecordId },
                }
            })
        };
        const response = await fetch(configData.api_url+"/tms/api/attendance", requestOptions);
        
        if(response.status == 403){
            navigate('/');
            closeLoader();
        }

        if (response.status == 200) {
            toast.success("Deleted Successfully");
            clearControls();
        }
        closeLoader();
    }



    function showLoader(){
        document.getElementById('linearloaderdiv').style.display = 'block';
    }
    
    function closeLoader(){
        document.getElementById('linearloaderdiv').style.display = 'none';
    }



    return (
            
            <Container>
                <Row>
                    <Col>
                    <Navbar></Navbar>
                    </Col>
                </Row>
                
            <Row className='mt-3'>
                <Col><h2>Offline Attendance</h2></Col>
                <Col className='d-flex flex-row-reverse'>
                    <button type="button" onClick={clearControls} className="btn btn-primary">Cancel</button>&nbsp;

                    {attendanceRecordId > 0 ?
                        <button type="button" onClick={deleteAttendance} className="btn btn-primary">Delete</button>
                        : ''
                    }&nbsp;
                    <button type="button" onClick={attendanceRecordId == 0 ? postAttendance : putAttendance} className="btn btn-primary">{attendanceRecordId == 0 ? 'Save' : 'Update'}</button>
                  
                  
                </Col>
            </Row>

<Row>
<Col sm={4}>
<div>
                        <span>Attendance Date</span>
                    </div>
<div>
<input onChange={clearStudents} type='date' className='w-100' id='txtDate'/>
</div>
    </Col>
    <Col sm={4}>
    <div>
                        <span>Year</span>
                    </div>
                    <div>
                    <select onChange={changeYear} className='w-100' id='ddlyear'>
                    <option key='0' value='0'></option>
                    {yearsArray != undefined && yearsArray.map((arrayItem) => <option key={arrayItem.id['N']} value={arrayItem.id['N']}>{arrayItem.name['S']}</option>)}
                </select>
                    </div>
    </Col>
    <Col sm={4}>
    <div>
                        <span>Subject</span>
                    </div>
                    <div>
                    <select onChange={clearStudents} className='w-100' id='ddlsubject'>
                    <option key='0' value='0'></option>
                    {subjectArray != undefined && subjectArray.map((arrayItem) => <option key={arrayItem.id['N']} value={arrayItem.id['N']}>{arrayItem.name['S']}</option>)}
                </select>
                    </div>
    </Col>
</Row>

<Row>
<Col sm={4}>
<div>
                        <span>Tutor</span>
                    </div>
                    <div>
                    <select onChange={clearStudents} className='w-100' id='ddltutor'>
                    {userType !='TUTOR' && <option key='0' value='0'></option>}
                    {tutorArray != undefined && tutorArray.map((arrayItem) => <option key={arrayItem.id['N']} value={arrayItem.id['N']}>{arrayItem.title['S']}.&nbsp;{arrayItem.firstname['S']}&nbsp;{arrayItem.surname['S']}</option>)}
                </select>
                    </div>
    </Col>
    <Col sm={4}>
        <div>
        <button type="button" onClick={getStudents} className="btn btn-secondary mt-3">Search Students</button>
        </div>
    </Col>
    <Col sm={4}>
    </Col>
</Row>

{
                                studentsArray!=undefined?
                                <>
                                {

<Row className='mt-2'>
<Col sm>

    <table className="table">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">First Name</th>
                <th scope="col">Sur Name</th>
                <th scope="col">Attendance</th>
                <th scope="col">H/W</th>
                <th scope="col">Marks</th>
                <th scope="col">Outstanding Balance</th>
            </tr>
        </thead>
        <tbody>

            {
                studentsArray!=undefined &&

                studentsArray.map((arrayItem, index) => 
                    <>
                        {
                    <tr>
                         <th scope="row">{index+1}</th>
                        <td>{arrayItem.firstname['S']}</td>
                        <td>{arrayItem.surname['S']}</td>
                        <td>
                            <div class="form-check">
                                        <input class="form-check-input" defaultChecked={arrayItem.attendance == '1'? true: false }  type="checkbox" value="" id={"flexCheckDefault_" + arrayItem.id['N']} />
                                <label class="form-check-label" for="flexCheckDefault">
                                </label>
                            </div>
                                </td>
                                <td>
                                    <div class="form-check">
                                        <input class="form-check-input" defaultChecked={arrayItem.homework == '1' ? true : false} type="checkbox" value="" id={"hwsubmittedCheckDefault_" + arrayItem.id['N']} />
                                        <label class="form-check-label" for="flexCheckDefault">
                                        </label>
                                    </div>
                                </td>
                                <td><input type='number' defaultValue={arrayItem.marks} id={"marks_" + arrayItem.id['N']} class="w-50" /></td>
                                <td><input type='number' defaultValue={arrayItem.outstandingbalance} id={"outstanding_" + arrayItem.id['N']} class="w-50" /></td>

                            </tr>

                        }
                    </>
               
                   

                    
                    )

            }

           
           
          
        </tbody>
    </table>

</Col>
</Row>

                                }  
                                </> : ''
}

           

            </Container>
      
    
  );
}

export default ReactComponent;