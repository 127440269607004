import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import exportFromJSON from 'export-from-json'
import configData from "../../configuration/config.json";
import Navbar from '../../../src/components/navbar/index';
import { useNavigate } from 'react-router-dom';

function ReactComponent() {
    const navigate = useNavigate();
    const [yearsArray, setYearsArray] = useState(null);
    const [subjectArray, setSubjectArray] = useState(null);
    const [tutorArray, setTutorArray] = useState(null);
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [studentsArray, setStudentsArray] = useState(null);

    let userType =  sessionStorage.getItem('type');
    let tutorID =  sessionStorage.getItem('userid');


    useEffect(() => {
        getYears();
        getSubjects();
        getTutors();
    }, []);


    async function getYears() {

        const requestOptions = {
            headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken') },
        };

        let res = await fetch(configData.api_url + "/tms/api/years", requestOptions);
        if(res.status == 403){
            navigate('/');
        }
        let json = await res.json();
        setYearsArray(json.Items);
    }

    async function getSubjects() {

        
        const requestOptions = {
            headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken') },
        };

        let res = await fetch(configData.api_url + "/tms/api/subjects", requestOptions);
        if(res.status == 403){
            navigate('/');
        }
        let json = await res.json();
        setSubjectArray(json.Items);
    }

  

    function clearControls() {
        document.getElementById('txtFromdate').value = '';
        document.getElementById('txtTodate').value = '';
        document.getElementById('ddlyear').value = '0';
    }

    async function getEnteredlists() {

      

        if (document.getElementById('txtFromdate').value == ''
            || document.getElementById('txtTodate').value == ''
            || document.getElementById('txtFromdate').value == undefined
            || document.getElementById('txtTodate').value == undefined
        ) {
            toast.error("Fromdate and Todate are mandatory");
            return;
        }

        showLoader();

        let fromdate = Date.parse(document.getElementById('txtFromdate').value).toString();
        let todate = Date.parse(document.getElementById('txtTodate').value).toString();
        let yearid = document.getElementById('ddlyear').value;
        let subjectid = document.getElementById('ddlsubject').value;
        let tutorid = document.getElementById('ddltutor').value;


        const requestOptions = {
            headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken') },
        };


        let existingAttendanceResponse = await fetch(configData.api_url + "/tms/api/attendance-checklist?"
            + 'fromdate=' + fromdate + '&'
            + 'todate=' + todate + '&'
            + 'yearid=' + yearid+ '&'
            + 'subjectid=' + subjectid.toString() + '&'
            + 'tutorid=' + tutorid.toString() + '&'
        ,requestOptions);

        if(existingAttendanceResponse.status == 403){
            navigate('/');
        }


        let existingJson = await existingAttendanceResponse.json();


        if (existingJson.Count == 0) {
            closeLoader();
            toast.error("No records found");
            return;
        }

        let ddlyear = document.getElementById("ddlyear");
        var selectedYear = ddlyear.options[ddlyear.selectedIndex].text;

        var exportData = [];

        existingJson.Items.map((itm, index) => {

            itm.students['L'].map((stds, index) => {

                let stdinfo = stds['M'];

                let exportDataItemRow = {

                    Year: itm.year_id['N'],
                    AttendanceDate: new Date(parseInt(itm.attendancedate['N'])).toLocaleDateString('en-CA'),
                    Subject: itm.subjectName,
                    Tutor: itm.tutorName,
                    FirstName: stdinfo.firstname,
                    SurName: stdinfo.surname,
                    Attendance: stdinfo.attendance['N'] == '1' ? 'Present' : 'Absent',
                    Homework: stdinfo.homework['N'] == '1' ? 'Submitted' : 'Not Submitted',
                    Marks: stdinfo.marks['N'] == '-1' ? '-' : stdinfo.marks['N'],
                    OutstandingBalance: stdinfo.outstandingbalance['N'] == '-1' ? '-' : stdinfo.outstandingbalance['N']
                }

                exportData.push(exportDataItemRow);

            })

        });



        exportFromJSON({ data: exportData, filename: yearid, exportType: 'xls' })
        closeLoader();
    }

    async function getChecklists() {

        if (document.getElementById('txtFromdate').value == ''
            || document.getElementById('txtTodate').value == ''
            || document.getElementById('txtFromdate').value == undefined
            || document.getElementById('txtTodate').value == undefined
        ) {
            toast.error("Fromdate and Todate are mandatory");
            return;
        }

        showLoader();

        let fromdate = Date.parse(document.getElementById('txtFromdate').value).toString();
        let todate = Date.parse(document.getElementById('txtTodate').value).toString();
        let yearid = document.getElementById('ddlyear').value;
        let subjectid = document.getElementById('ddlsubject').value;
        let tutorid = document.getElementById('ddltutor').value;

        const requestOptions = {
            headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken') },
        };



        let existingAttendanceResponse = await fetch(configData.api_url + "/tms/api/attendance-checklist?"
            + 'fromdate=' + fromdate + '&'
            + 'todate=' + todate + '&'
            + 'yearid=' + yearid+ '&'
            + 'subjectid=' + subjectid.toString() + '&'
            + 'tutorid=' + tutorid.toString() + '&'
        ,requestOptions);

        if(existingAttendanceResponse.status == 403){
            navigate('/');
        }

        let existingJson = await existingAttendanceResponse.json();


        if (existingJson.Count == 0) {
            closeLoader();
            toast.error("No records found");
            return;
        }

        let ddlyear = document.getElementById("ddlyear");
        var selectedYear = ddlyear.options[ddlyear.selectedIndex].text;

        var exportData = [];

        existingJson.Items.map((itm, index) => {

            itm.students['L'].map((stds, index) => {

                let stdinfo = stds['M'];

                let subjects = stdinfo.attendance['N'] == '1' ? itm.subjectName : '';
                let columnName = days[new Date(parseInt(itm.attendancedate['N'])).getDay()] + "-Subjects";
                let columnNameFees = days[new Date(parseInt(itm.attendancedate['N'])).getDay()] + "-Fees";

                let existsEntryRecord =
                    exportData.filter((exrecord, index) =>
                        exrecord?.Id == stdinfo.id['N']
                    );


                let existingSubjects = '';

                if (existsEntryRecord.length > 0) {
                    let deleteIndex = exportData.indexOf(existsEntryRecord[0]);

                    existingSubjects = exportData[deleteIndex][columnName];

                    let subjectsNames = '';
                    if (existingSubjects == undefined) {
                        subjectsNames = subjects;
                    }
                    else if (subjects == '' || subjects == undefined) {
                        subjectsNames = existingSubjects;
                    } else {

                        subjectsNames = existingSubjects == '' ? subjects :
                            existingSubjects + '/' + subjects;

                    }

                    exportData[deleteIndex][columnName] = subjectsNames
                    exportData[deleteIndex][columnNameFees] = 0.00;
                    
                } else {

                    let subjectsNames = '';

                    if (subjects == '' || subjects == undefined) {
                        subjectsNames = existingSubjects;
                    } else {

                        subjectsNames = existingSubjects == '' ? subjects :
                            existingSubjects ?? '' + '/' + subjects;

                    }

                    let exportDataItemRow = {
                        Id: stdinfo.id['N'],
                        FirstName: stdinfo.firstname,
                        SurName: stdinfo.surname,
                        Year: itm.year_id['N'],
                        [columnName]: subjectsNames,
                        [columnNameFees]:0.00
                    }
                    
                    exportData.push(exportDataItemRow);
                }
            })

        });



        exportFromJSON({
            data:
                exportData

            , filename: yearid, exportType: 'xls'
        })

        closeLoader();
    }


    function showLoader(){
        document.getElementById('linearloaderdiv').style.display = 'block';
    }
    
    function closeLoader(){
        document.getElementById('linearloaderdiv').style.display = 'none';
    }

    function clearStudents() {
        setStudentsArray(null);
    }

    function changeYear(){
        setSubjectArray(null);
        showLoader();
        getSubjects();
    }

    async function getSubjects() {

        const requestOptions = {
            headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken') },
        };

        let ddlyear = document.getElementById('ddlyear').value;

        let res = await fetch(configData.api_url+"/tms/api/subjects/years/"+ddlyear, requestOptions);

        if(res.status == 403){
            navigate('/');
        }

        let json = await res.json();
        setSubjectArray(json.Items);

        closeLoader();
    }

    async function getTutors() {

        const requestOptions = {
            headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken') },
        };

        let tutorsAPI = configData.api_url+"/tms/api/tutors";

        if(userType  == 'TUTOR'){
            tutorsAPI = configData.api_url+"/tms/api/tutors/"+tutorID;
        }

        let res = await fetch(tutorsAPI, requestOptions);

        if(res.status == 403){
            navigate('/');
        }

        let json = await res.json();
        setTutorArray(json.Items);
    }

    return (

        <Container>
              <Row>
                    <Col>
                    <Navbar></Navbar>
                    </Col>
                </Row>
                <Row className='mt-3'>
                <Col><h2>Checklist</h2></Col>
            </Row>

<Row>
<Col sm={4}>
<div><span>From Date</span></div>
<div><input type='date' className='w-100' id='txtFromdate' /></div>
</Col>

<Col sm={4}>
<div><span>To Date</span></div>
<div><input type='date' className='w-100' id='txtTodate' /></div>
</Col>

<Col sm={4}>
<div><span>Year</span></div>
<div><select className='w-100' id='ddlyear' onChange={changeYear}>
                    <option key='0' value='0'>All</option>
                    {yearsArray != undefined && yearsArray.map((arrayItem) => <option key={arrayItem.id['N']} value={arrayItem.id['N']}>{arrayItem.name['S']}</option>)}
                </select></div>
</Col>

</Row>
<Row>
<Col sm={4}>
    <div>
                        <span>Subject</span>
                    </div>
                    <div>
                    <select onChange={clearStudents} className='w-100' id='ddlsubject'>
                    <option key='0' value='0'></option>
                    {subjectArray != undefined && subjectArray.map((arrayItem) => <option key={arrayItem.id['N']} value={arrayItem.id['N']}>{arrayItem.name['S']}</option>)}
                </select>
                    </div>
    
</Col>
<Col sm={4}>
<div>
                        <span>Tutor</span>
                    </div>
                    <div>
                    <select onChange={clearStudents} className='w-100' id='ddltutor'>
                    {userType !='TUTOR' && <option key='0' value='0'></option>}
                    {tutorArray != undefined && tutorArray.map((arrayItem) => <option key={arrayItem.id['N']} value={arrayItem.id['N']}>{arrayItem.title['S']}.&nbsp;{arrayItem.firstname['S']}&nbsp;{arrayItem.surname['S']}</option>)}
                </select>
                    </div>
    </Col>
</Row>



            <Row className='mt-2'>
                <Col sm>
                    <button type="button" onClick={getEnteredlists} className="btn btn-primary">Plain Entries</button>
                    &nbsp;
                    <button type="button" onClick={getChecklists} className="btn btn-primary">Checklist</button>
                </Col>
            </Row>

        </Container>


    );
}

export default ReactComponent;