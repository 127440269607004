import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import exportFromJSON from 'export-from-json'
import configData from "../../configuration/config.json";
import Navbar from '../../../src/components/navbar/index';
import { useNavigate } from 'react-router-dom';

function ReactComponent() {
    const navigate = useNavigate();
    const [yearsArray, setYearsArray] = useState(null);
    const [subjectArray, setSubjectArray] = useState(null);
    const [tutorArray, setTutorArray] = useState(null);
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [studentsArray, setStudentsArray] = useState(null);

    useEffect(() => {
    }, []);


  

    async function getEnteredlists() {



        if (document.getElementById('txtFromdate').value == ''
            || document.getElementById('txtFromdate').value == undefined
        ) {
            toast.error("Week number is mandatory");
            return;
        }

        showLoader();

        let weekValue = document.getElementById('txtFromdate').value;
        
        let weekValueSplitted = weekValue.split('-W');

        let sundayFromWeekNumber = getSundayFromWeekNum(weekValueSplitted[1],weekValueSplitted[0]);

        let mondayFromSunday = new Date(sundayFromWeekNumber.setDate(sundayFromWeekNumber.getDate() + 1));

        let formatted = formatDate(mondayFromSunday);

        var mondayWeekStartDateNumber = Date.parse(formatted).toString();
        
        const requestOptions = {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken') },
        };


        let existingAttendanceResponse = await fetch(configData.api_url + "/tms/api/students-reports/weekly-report?"
            + 'fromdate=' + mondayWeekStartDateNumber + '&'
            + 'weeknumber=' + weekValueSplitted[1]
            , requestOptions);

        if (existingAttendanceResponse.status == 403) {
            navigate('/');
        }


        let existingJson = await existingAttendanceResponse.json();


        if (existingJson.Count == 0) {
            closeLoader();
            toast.error("No records found");
            return;
        }

        exportFromJSON({ data: existingJson, filename: 'WeeklyReport', exportType: 'xls' })

        closeLoader();
    }


    function showLoader() {
        document.getElementById('linearloaderdiv').style.display = 'block';
    }

    function closeLoader() {
        document.getElementById('linearloaderdiv').style.display = 'none';
    }

    const getSundayFromWeekNum = (weekNum, year) => {
        const sunday = new Date(year, 0, (1 + (weekNum - 1) * 7));
        while (sunday.getDay() !== 0) {
            sunday.setDate(sunday.getDate() - 1);
        }
        return sunday;
      }

      function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      }

      function formatDate(date) {
        return (
          [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
          ].join('-') 
        );
      }


    return (

        <Container>
            <Row>
                <Col>
                    <Navbar></Navbar>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col><h2>Students Report - By Week</h2></Col>
            </Row>

            <Row>
                <Col sm={4}>
                    <div><span>Week Number</span></div>
                    <div><input type='week' className='w-100' id='txtFromdate' /></div>
                </Col>


            </Row>

            <Row className='mt-2'>
                <Col sm>
                    <button type="button" onClick={getEnteredlists} className="btn btn-primary">Export</button>
                </Col>
            </Row>

        </Container>


    );
}

export default ReactComponent;