import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import exportFromJSON from 'export-from-json'
import configData from "../../configuration/config.json";
import Navbar from '../../../src/components/navbar/index';
import { useNavigate } from 'react-router-dom';



function ReactComponent() {
    const navigate = useNavigate();
    let studentID = window.location.pathname.split('/')[2]

    const [yearsArray, setYearsArray] = useState(null);
    const [studentsArray, setStudentsArray] = useState(null);
    const [subjectArray, setSubjectArray] = useState(null);

    useEffect(() => {
        getYears();
        getSubjects();   
         if (studentID != undefined) {
            getStudent();
        }
    }, []);


    async function getSubjects() {
        showLoader();
        const requestOptions = {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken') },
        };

        let res = await fetch(configData.api_url + "/tms/api/subjects", requestOptions);

        if (res.status == 403) {
            navigate('/');
        }

        let json = await res.json();
        setSubjectArray(json.Items);
        closeLoader();
      

    }

    async function getYears() {

        const requestOptions = {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken') },
        };

        let res = await fetch(configData.api_url + "/tms/api/years", requestOptions);
        if (res.status == 403) {
            navigate('/');
        }
        let json = await res.json();
        setYearsArray(json.Items);
    }


    async function getStudent() {
        showLoader();
        const requestOptions = {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken') },
        };

        let yearid = document.getElementById('ddlyear').value;

        let res = await fetch(configData.api_url + "/tms/api/students/" + studentID, requestOptions);

        if (res.status == 403) {
            navigate('/');
        }

        let json = await res.json();
        setStudentsArray(json.Items);

        if (json.Items != undefined && json.Items.length > 0) {
            let studentSubjects = json.Items[0].subjects['L'];
            if (studentSubjects != undefined && studentSubjects.length > 0) {

                studentSubjects.map(sub => {
                    
                    let subjectElement = document.getElementById('subjectCheckDefault_' + sub['N']);
                    if (subjectElement != undefined) {
                        subjectElement.checked = true;
                    }


                })

            }

            document.getElementById('ddlyear').value = json.Items[0].yearid['N'];
        } else {
            
            document.getElementById('ddlyear').value = 0;
        }



        closeLoader();
    }


    function clearStudents() {
        setStudentsArray(null);
    }


    function showLoader() {
        document.getElementById('linearloaderdiv').style.display = 'block';
    }

    function closeLoader() {
        document.getElementById('linearloaderdiv').style.display = 'none';
    }


    async function postStudent() {

        showLoader();

        let selectedSubjects = [];

        subjectArray != undefined &&
            subjectArray.map((arrayItem, index) => {

                if (document.getElementById("subjectCheckDefault_" + arrayItem.id['N']).checked == true) {
                    selectedSubjects.push({ "N": arrayItem.id['N'] });
                }

            });

        if (
            (document.getElementById('txtFirstName').value.trim().replace(' ', '') == '') ||
            (document.getElementById('txtSurName').value.trim().replace(' ', '') == '') ||
            (document.getElementById('ddlyear').value == '0') || selectedSubjects.length == 0) {

            toast.error("Please fill Names, Year, Subjects");
            closeLoader();
            return;
        }

        let createdOrModifiedValue = { "N": sessionStorage.getItem("userid") };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken')
            },
            body: JSON.stringify({
                "TableName": "students_cfg",
                "Item": {
                    "subjects": { "L": selectedSubjects },
                    "id": { "N": "0" },
                    "yearid": { "N": document.getElementById('ddlyear').value.trim() },
                    "email": { "S": document.getElementById('txtEmail').value.trim() },
                    "firstname": { "S": document.getElementById('txtFirstName').value.trim() },
                    "surname": { "S": document.getElementById('txtSurName').value.trim() },
                    "parentcontactno": { "S": document.getElementById('txtParentContactNo').value.trim() },
                    "studentcontactno": { "S": document.getElementById('txtStudentContactNo').value.trim() },
                    "createdby": createdOrModifiedValue,
                    "modifiedby": createdOrModifiedValue,
                }
            })
        };
        const response = await fetch(configData.api_url + "/tms/api/students", requestOptions);

        if (response.status == 403) {
            navigate('/');
        }

        if (response.status == 200) {
            toast.success("Posted Successfully");
            clearControls();
        }


        closeLoader();
    }


    function clearControls() {

        document.getElementById('txtFirstName').value = '';
        document.getElementById('txtSurName').value = '';
        document.getElementById('txtEmail').value = '';
        document.getElementById('txtParentContactNo').value = '';
        document.getElementById('txtStudentContactNo').value = '';
        document.getElementById('ddlyear').value = '0';
        subjectArray != undefined &&
        subjectArray.map((arrayItem, index) => {

            if (document.getElementById("subjectCheckDefault_" + arrayItem.id['N']).checked == true) {
                document.getElementById("subjectCheckDefault_" + arrayItem.id['N']).checked = false;
            }

        });

    }

    async function putStudents() {



        let createdOrModifiedValue = sessionStorage.getItem("userid");

        let modifiedSubjects = [];

        subjectArray != undefined &&
            subjectArray.map((arrayItem, index) => {

                if (document.getElementById("subjectCheckDefault_" + arrayItem.id['N']).checked == true) {
                    modifiedSubjects.push(Number.parseInt(arrayItem.id['N']));
                }

            });

        var params = {
            TableName: 'students_cfg',
            Key: {
                "id": Number.parseInt(studentID)
            },
            UpdateExpression: 'set subjects = :subjects, modifiedby = :modifiedby, firstname = :firstname, surname = :surname, email = :email, parentcontactno = :parentcontactno, studentcontactno = :studentcontactno, yearid = :yearid',
            ExpressionAttributeValues: {
                ':subjects': modifiedSubjects,
                ':modifiedby': Number.parseInt(createdOrModifiedValue),
                ':firstname': document.getElementById('txtFirstName').value,
                ':surname': document.getElementById('txtSurName').value,
                ':email': document.getElementById('txtEmail').value,
                ':parentcontactno': document.getElementById('txtParentContactNo').value,
                ':studentcontactno': document.getElementById('txtStudentContactNo').value,
                ':yearid': Number.parseInt(document.getElementById('ddlyear').value),
            }
        };


        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken')
            },
            body: JSON.stringify(params)
        };
        const response = await fetch(configData.api_url + "/tms/api/students", requestOptions);

        if (response.status == 403) {
            navigate('/');
        }

        if (response.status == 200) {
            toast.success("Updated Successfully");
            clearControls();
            navigate('/students');
        } else {
            toast.error(response.status + '-' + response.statusText);
        }
    }

    function cancelStudent() {
        navigate('/students')
    }

   

    return (
        <Container>

            <Row>
                <Col>
                    <Navbar>
                    </Navbar>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col><h2>{studentID == undefined ? 'New Student' : 'Student Edit'}</h2></Col>
            </Row>


            <Row className='mt-2'>
                <Col sm={4}>
                    <div>
                        <span>First Name</span>
                    </div>
                    <div>
                        <input type='text' defaultValue={studentsArray != null && studentsArray.length > 0 ?
                            studentsArray[0].firstname['S'] : ''

                        } className='w-100' id='txtFirstName' />
                    </div>

                </Col>
                <Col sm={4}>
                    <div>
                        <span>Sur Name</span>
                    </div>
                    <div>
                        <input type='text' defaultValue={studentsArray != null && studentsArray.length > 0 ?
                            studentsArray[0].surname['S'] : ''

                        } className='w-100' id='txtSurName' />
                    </div>

                </Col>
                <Col sm={4}>
                    <div>
                        <span>Email</span>
                    </div>
                    <div>
                        <input type='text' defaultValue={studentsArray != null && studentsArray.length > 0 ?
                            studentsArray[0].email['S'] : ''

                        } className='w-100' id='txtEmail' />
                    </div>

                </Col>
            </Row>


            <Row className='mt-2'>
                <Col sm={4}>
                    <div>
                        <span>Parent Contact No</span>
                    </div>
                    <div>
                        <input type='text' defaultValue={studentsArray != null && studentsArray.length > 0 ?
                            studentsArray[0].parentcontactno['S'] : ''

                        } className='w-100' id='txtParentContactNo' />
                    </div>

                </Col>
                <Col sm={4}>
                    <div>
                        <span>Student Contact No</span>
                    </div>
                    <div>
                        <input type='text' defaultValue={studentsArray != null && studentsArray.length > 0 ?
                            studentsArray[0].studentcontactno['S'] : ''

                        } className='w-100' id='txtStudentContactNo' />
                    </div>

                </Col>
                <Col sm={4}>
                    <div>
                        <span>Year</span>
                    </div>
                    <div>
                        <select className='w-100' id='ddlyear'>
                            <option key='0' value='0'></option>
                            {yearsArray != undefined && yearsArray.map((arrayItem) =>
                                <option key={arrayItem.id['N']} value={arrayItem.id['N']}>{arrayItem.name['S']}</option>)
                            }
                        </select>
                    </div>

                </Col>
            </Row>

            <Row className='mt-2'>
                <Col sm={12}>
                    <div>
                        <span>Subjects</span>
                    </div>
                    <div>
                        <Row>
                            {
                                subjectArray != undefined &&
                                subjectArray.map((arrayItem, index) =>

                                    <>
                                        {
                                            <Col className='col-3'>
                                                <div class="form-check w-100">
                                                    <input class="form-check-input" defaultChecked={arrayItem.attendance == '1' ? true : false}
                                                        type="checkbox" value="" id={"subjectCheckDefault_" + arrayItem.id['N']} />
                                                    <label class="form-check-label" for="subjectCheckDefault_">
                                                        {arrayItem.name['S']}
                                                    </label>
                                                </div>
                                            </Col>

                                        }
                                    </>
                                )
                            }
                        </Row>
                    </div>

                </Col>

            </Row>

            <Row className='mt-3'>
                <Col className='d-flex'>
                    <button type="button" onClick={studentID == undefined ? postStudent : putStudents} className="btn btn-primary">{studentID == undefined ? 'Save' : 'Update'}</button>&nbsp;
                    <button type="button" onClick={cancelStudent} className="btn btn-primary">{studentID == undefined ? 'Back' : 'Cancel'}</button>
                </Col>
            </Row>

        </Container>

    );
}

export default ReactComponent;