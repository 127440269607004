import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import exportFromJSON from 'export-from-json'
import configData from "../../configuration/config.json";
import Navbar from '../../../src/components/navbar/index';
import { useNavigate } from 'react-router-dom';



function ReactComponent() {
    const navigate = useNavigate();
    let subjectID = window.location.pathname.split('/')[2]

    const [subjectArray, setSubjectArray] = useState(null);

    useEffect(() => {
         if (subjectID != undefined) {
            getSubjects();
        }
    }, []);


    async function getSubjects() {
        showLoader();
        const requestOptions = {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken') },
        };

        let res = await fetch(configData.api_url + "/tms/api/subjects/"+subjectID, requestOptions);

        if (res.status == 403) {
            navigate('/');
        }

        let json = await res.json();
        setSubjectArray(json.Items);
        closeLoader();
      

    }

    function clearStudents() {
        setSubjectArray(null);
    }


    function showLoader() {
        document.getElementById('linearloaderdiv').style.display = 'block';
    }

    function closeLoader() {
        document.getElementById('linearloaderdiv').style.display = 'none';
    }


    async function post() {

        showLoader();

        if (
            (document.getElementById('txtSubjectName').value.trim().replace(' ', '') == '') ) {

            toast.error("Please fill Subject Name");
            closeLoader();
            return;
        }

        let createdOrModifiedValue = { "N": sessionStorage.getItem("userid") };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken')
            },
            body: JSON.stringify({
                "TableName": "subjects_cfg",
                "Item": {
                    "id": { "N": "0" },
                    "name": { "S": document.getElementById('txtSubjectName').value },
                    "createdby": createdOrModifiedValue,
                    "modifiedby": createdOrModifiedValue,
                }
            })
        };
        const response = await fetch(configData.api_url + "/tms/api/subjects", requestOptions);

        if (response.status == 403) {
            navigate('/');
        }

        if (response.status == 200) {
            toast.success("Posted Successfully");
            clearControls();
        }


        closeLoader();
    }


    function clearControls() {
        document.getElementById('txtSubjectName').value = '';
    }

    async function put() {



        let createdOrModifiedValue = sessionStorage.getItem("userid");

      

        var params = {
            TableName: 'subjects_cfg',
            Key: {
                "id": Number.parseInt(subjectID)
            },
            UpdateExpression: 'set modifiedby = :modifiedby, #subjectname = :subjectname',
            ExpressionAttributeValues: {
                ':modifiedby': Number.parseInt(createdOrModifiedValue),
                ':subjectname': document.getElementById('txtSubjectName').value,
            },
            ExpressionAttributeNames:{
                "#subjectname": "name"
              }
        };


        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken')
            },
            body: JSON.stringify(params)
        };
        const response = await fetch(configData.api_url + "/tms/api/subjects", requestOptions);

        if (response.status == 403) {
            navigate('/');
        }

        if (response.status == 200) {
            toast.success("Updated Successfully");
            clearControls();
            navigate('/subjects');
        } else {
            toast.error(response.status + '-' + response.statusText);
        }
    }

    function cancelStudent() {
        navigate('/subjects')
    }

   

    return (
        <Container>

            <Row>
                <Col>
                    <Navbar>
                    </Navbar>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col><h2>{subjectID == undefined ? 'New Subject' : 'Subject Edit'}</h2></Col>
            </Row>


            <Row className='mt-2'>
                <Col sm={4}>
                    <div>
                        <span>Name</span>
                    </div>
                    <div>
                        <input type='text' defaultValue={subjectArray != null && subjectArray.length > 0 ?
                            subjectArray[0].name['S'] : ''

                        } className='w-100' id='txtSubjectName' />
                    </div>
</Col>
            </Row>

            <Row className='mt-3'>
                <Col className='d-flex'>
                    <button type="button" onClick={subjectID == undefined ? post : put} className="btn btn-primary">{subjectID == undefined ? 'Save' : 'Update'}</button>&nbsp;
                    <button type="button" onClick={cancelStudent} className="btn btn-primary">{subjectID == undefined ? 'Back' : 'Cancel'}</button>
                </Col>
            </Row>

        </Container>

    );
}

export default ReactComponent;