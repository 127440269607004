import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import exportFromJSON from 'export-from-json'
import configData from "../../configuration/config.json";
import Navbar from '../../../src/components/navbar/index';
import { redirect, useNavigate } from 'react-router-dom';


function ReactComponent() {
  const navigate = useNavigate();
  const [subjectArray, setSubjectArray] = useState(null);

  useEffect(() => {
    getSubjects();   
  }, []);



  async function getSubjects() {
    showLoader();
    const requestOptions = {
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken') },
    };

    let res = await fetch(configData.api_url + "/tms/api/subjects", requestOptions);

    if (res.status == 403) {
        navigate('/');
    }

    let json = await res.json();
    setSubjectArray(json.Items);
    closeLoader();
  

}


  function showLoader() {
    document.getElementById('linearloaderdiv').style.display = 'block';
  }

  function closeLoader() {
    document.getElementById('linearloaderdiv').style.display = 'none';
  }

  function view(stu) {
    let studentID = stu.currentTarget.id.split('_')[1];
    
    navigate('/subjects-view/' + studentID);
  }


  function createNew(){
    navigate('/subjects-view');
  }

  async function deleteSubject(stu) {
debugger;
    let deleteConfirmation = window.confirm('Are you sure to delete the Subject?.')

    if (deleteConfirmation) {

      showLoader();
      const requestOptions = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken')
        },

      };

      let subjectId = stu.target.id.split('_')[1];

      const response = await fetch(configData.api_url + "/tms/api/subjects/" + subjectId, requestOptions);

      if (response.status == 403) {
        navigate('/');
      }

      if (response.status == 200) {
        getSubjects();
        toast.success("Deleted Successfully");
      }
      closeLoader();
    }

  }

  return (
    <Container>
      <Row>
        <Col>
          <Navbar></Navbar>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col><h2>Subjects</h2></Col>
        <Col className="d-flex justify-content-end"><button onClick={createNew} type="button" id='btnNewStudent' className="btn btn-secondary">New Subject</button></Col>
      </Row>

    

      {
                subjectArray != undefined ?
                  <>
                    {

      <Row>
        <Col>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>

              {
                subjectArray != undefined &&

                subjectArray.map((arrayItem, index) =>
                  <>
                    {
                      <tr>
                        <th scope="row">{index + 1}</th>
                        <td>{arrayItem.name['S']}</td>
                        <td>
                          <div>
                            <button type="button" id={'btnedit_' + arrayItem.id['N']} onClick={view} className="btn btn-primary">Edit</button>{' '}
                            <button type="button" id={'btndelete_' + arrayItem.id['N']} onClick={deleteSubject} className="btn btn-primary">Delete</button>
                          </div>
                        </td>
                      </tr>

                    }
                  </>




                )

              }




            </tbody>
          </table>

        </Col>

      </Row>

            }
</> : ''
                
            }
    </Container>

  );
}

export default ReactComponent;