import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import exportFromJSON from 'export-from-json'
import configData from "../../configuration/config.json";
import Navbar from '../../../src/components/navbar/index';
import { useNavigate } from 'react-router-dom';



function ReactComponent() {
    const navigate = useNavigate();

    useEffect(() => {

    }, []);


    function showLoader() {
        document.getElementById('linearloaderdiv').style.display = 'block';
    }

    function closeLoader() {
        document.getElementById('linearloaderdiv').style.display = 'none';
    }



    async function put() {

        showLoader();

        if (
            (document.getElementById('txtPassword').value.trim().replace(' ', '') == '') ) {

            toast.error("Please fill new Password");
            closeLoader();
            return;
        }

        if (
            (document.getElementById('txtReEnterPassword').value.trim().replace(' ', '') == '') ) {

            toast.error("Please fill Re-enter New Password");
            closeLoader();
            return;
        }

        if (
            (document.getElementById('txtPassword').value.trim().replace(' ', '')) !=
            (document.getElementById('txtReEnterPassword').value.trim().replace(' ', ''))
            ) {

            toast.error("Re-entered Password not matched.");
            closeLoader();
            return;
        }



        let createdOrModifiedValue = sessionStorage.getItem("userid");

        var params = {
            TableName: 'staff_cfg',
            Key: {
                "id": Number.parseInt(createdOrModifiedValue)
            },
            UpdateExpression: 'set modifiedby = :modifiedby, #temporarypassword = :temporarypassword, #password = :password',
            ExpressionAttributeValues: {
                ':modifiedby': Number.parseInt(createdOrModifiedValue),
                ':temporarypassword': '',
                ':password': document.getElementById('txtPassword').value,
            },
            ExpressionAttributeNames:{
                "#temporarypassword": "temporarypassword",
                "#password": "password",
              }
        };


        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken')
            },
            body: JSON.stringify(params)
        };
        const response = await fetch(configData.api_url + "/tms/api/tutors", requestOptions);

        if (response.status == 403) {
            navigate('/');
        }

        if (response.status == 200) {
            toast.success("Updated Successfully");
            clearControls();
        } else {
            toast.error(response.status + '-' + response.statusText);
        }

        closeLoader();
    }


    function clearControls(){
        document.getElementById('txtPassword').value = '';
        document.getElementById('txtReEnterPassword').value = '';
    }


    return (
        <Container>

            <Row>
                <Col>
                    <Navbar>
                    </Navbar>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col><h2>Change Password</h2></Col>
            </Row>


            <Row className='mt-2'>
                <Col sm={4}>
                    <div>
                        <span>New Password</span>
                    </div>
                    <div>
                        <input type='password' className='w-100' id='txtPassword' />
                    </div>
</Col>

<Col sm={4}>
                    <div>
                        <span>Re-enter New Password</span>
                    </div>
                    <div>
                        <input type='password' className='w-100' id='txtReEnterPassword' />
                    </div>
</Col>

            </Row>

            <Row className='mt-3'>
                <Col className='d-flex'>
                    <button type="button" onClick={put} className="btn btn-primary">Update</button>
                </Col>
            </Row>

        </Container>

    );
}

export default ReactComponent;