import './App.css';
import Offlineattendance from './pages/offlineattendance/index';
import WeeklyChecklist from './pages/weeklychecklist/index';
import Students from './pages/students/index';
import Login from './pages/login'
import StudentsView from './pages/students/view'
import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';
import Subjects from './pages/subjects/index';
import SubjectsView from './pages/subjects/view';
import Fees from './pages/fees/index'
import StudentsReportsByWeek from './pages/studentsreports/byweek'
import StudentsReportsByMonth from './pages/studentsreports/bymonth'
import Tutors from './pages/tutors/index'
import TutorsView from './pages/tutors/view'
import TutorsReportsByWeek from './pages/tutorsreports/byweek'
import ChangePassword from './pages/profile/index'

function App() {
    return (
      <Router>
          <Routes>
            
              <Route path='/' exact element={<Login />} />
              <Route path='/attendance' element={<Offlineattendance />} />
                <Route path='/weekly-checklist' element={<WeeklyChecklist />} />
                <Route path='/students' element={<Students />} />
                <Route path='/students-view' element={<StudentsView />} />
                <Route path='/students-view/:id' element={<StudentsView />} />
                <Route path='/subjects' element={<Subjects />} />
                <Route path='/subjects-view' element={<SubjectsView />} />
                <Route path='/subjects-view/:id' element={<SubjectsView />} />
                <Route path='/fees' element={<Fees />} />
                <Route path='/students-reports-by-week' element={<StudentsReportsByWeek />} />
                <Route path='/students-reports-by-month' element={<StudentsReportsByMonth />} />
                <Route path='/tutors' element={<Tutors />} />
                <Route path='/tutors-view' element={<TutorsView />} />
                <Route path='/tutors-view/:id' element={<TutorsView />} />
                <Route path='/tutors-reports-by-week' element={<TutorsReportsByWeek />} />
                <Route path='/change-password' element={<ChangePassword />} />
          </Routes>
      </Router>

  );
}

export default App;
