import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configData from "../configuration/config.json";
import { useNavigate} from 'react-router-dom';
import { useEffect, useState } from "react";

function ReactComponent() {

    
    const navigate = useNavigate();

    useEffect(() => {
       sessionStorage.clear();
    }, []);

   async function Authenticate(){
        
if(document.getElementById('txtusername').value == undefined ||
document.getElementById('txtusername').value.replaceAll(' ', '') == '' ||
document.getElementById('txtpassword').value == undefined ||
document.getElementById('txtpassword').value.replaceAll(' ', '') == ''
){
    toast.error('All fields are mandatory');
    return;
}


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "username": document.getElementById('txtusername').value,
               "password":document.getElementById('txtpassword').value
            })
        };
        let response = await fetch(configData.api_url+"/tms/auth/api/signin", requestOptions);
debugger;
        if (response.status == 200) {
            let json = await response.json();
            sessionStorage.setItem("tmsjwttoken", json.token);
            sessionStorage.setItem("userid", json.userid);
            sessionStorage.setItem("surname", json.surname);
            sessionStorage.setItem("type", json.type);
            navigate('/attendance');
        }else{
            let json = await response.json();
            toast.error(json.message);
        }

    }


    return (


        
        <Container>
<Row>
<Col>
<h2>Login</h2>


</Col>

</Row>

<Row className='mt-2'>
    <Col sm={1}>
<span>Username</span>    </Col>
<Col><input type='text' id='txtusername'></input></Col>
    
</Row>

<Row className='mt-2'>
    <Col sm={1}>
<span>Password</span>    </Col>
<Col><input type='password' id='txtpassword'></input></Col>
    
</Row>

<Row className='mt-2'>
    <Col sm={1}>
<span></span>    </Col>
<Col><Button onClick={Authenticate}>Submit</Button></Col>
    
</Row>


        </Container>
        



    )

}

export default ReactComponent;