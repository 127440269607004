import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import exportFromJSON from 'export-from-json'
import configData from "../../configuration/config.json";
import Navbar from '../../../src/components/navbar/index';
import { useNavigate } from 'react-router-dom';



function ReactComponent() {
    const navigate = useNavigate();
    let tutorID = window.location.pathname.split('/')[2]

    const [tutorArray, setTutorArray] = useState(null);

    useEffect(() => {
         if (tutorID != undefined) {
            getTutors();
        }
    }, []);


    async function getTutors() {
        showLoader();
        const requestOptions = {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken') },
        };

        let res = await fetch(configData.api_url + "/tms/api/tutors/configuration/details/"+tutorID, requestOptions);

        if (res.status == 403) {
            navigate('/');
        }

        let json = await res.json();
        setTutorArray(json.Items);
        closeLoader();
      

    }

    function clearStudents() {
        setTutorArray(null);
    }


    function showLoader() {
        document.getElementById('linearloaderdiv').style.display = 'block';
    }

    function closeLoader() {
        document.getElementById('linearloaderdiv').style.display = 'none';
    }


    async function post() {

        showLoader();

        if (
            (document.getElementById('titles').value.trim().replace(' ', '') == 'NA') ) {

            toast.error("Please select Title");
            closeLoader();
            return;
        }


        if (
            (document.getElementById('txtFirstName').value.trim().replace(' ', '') == '') ) {

            toast.error("Please fill First Name");
            closeLoader();
            return;
        }

        if (
            (document.getElementById('txtSurName').value.trim().replace(' ', '') == '') ) {

            toast.error("Please fill Sur Name");
            closeLoader();
            return;
        }

        if (
            (document.getElementById('types').value.trim().replace(' ', '') == 'NA') ) {

            toast.error("Please select Type");
            closeLoader();
            return;
        }

        if (
            (document.getElementById('txtLoginUser').value.trim().replace(' ', '') == '') ) {

            toast.error("Please fill Login Username");
            closeLoader();
            return;
        }

        if (
            (document.getElementById('txtLoginPassword').value.trim().replace(' ', '') == '') ) {

            toast.error("Please fill Temporary Password");
            closeLoader();
            return;
        }


        let createdOrModifiedValue = { "N": sessionStorage.getItem("userid") };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken')
            },
            body: JSON.stringify({
                "TableName": "staff_cfg",
                "Item": {
                    "id": { "N": "0" },
                    "title": { "S": document.getElementById('titles').value },
                    "firstname": { "S": document.getElementById('txtFirstName').value },
                    "surname": { "S": document.getElementById('txtSurName').value },
                    "type": { "S": document.getElementById('types').value },
                    "loginusername": { "S": document.getElementById('txtLoginUser').value },
                    "temporarypassword": { "S": document.getElementById('txtLoginPassword').value },
                    "password": { "S": '' },
                    "createdby": createdOrModifiedValue,
                    "modifiedby": createdOrModifiedValue,
                }
            })
        };
        const response = await fetch(configData.api_url + "/tms/api/tutors", requestOptions);

        if (response.status == 403) {
            navigate('/');
        }

        if (response.status == 200) {
            toast.success("Posted Successfully");
            clearControls();
        }


        closeLoader();
    }


    function clearControls() {
        document.getElementById('titles').value = 'NA';
        document.getElementById('txtFirstName').value = '';
        document.getElementById('txtSurName').value = '';
        document.getElementById('types').value = 'NA';
        document.getElementById('txtLoginUser').value = '';
        document.getElementById('txtLoginPassword').value = '';
    }

    async function put() {


        if (
            (document.getElementById('titles').value.trim().replace(' ', '') == 'NA') ) {

            toast.error("Please select Title");
            closeLoader();
            return;
        }


        if (
            (document.getElementById('txtFirstName').value.trim().replace(' ', '') == '') ) {

            toast.error("Please fill First Name");
            closeLoader();
            return;
        }

        if (
            (document.getElementById('txtSurName').value.trim().replace(' ', '') == '') ) {

            toast.error("Please fill Sur Name");
            closeLoader();
            return;
        }

        if (
            (document.getElementById('types').value.trim().replace(' ', '') == 'NA') ) {

            toast.error("Please select Type");
            closeLoader();
            return;
        }

        if (
            (document.getElementById('txtLoginUser').value.trim().replace(' ', '') == '') ) {

            toast.error("Please fill Login Username");
            closeLoader();
            return;
        }


        let createdOrModifiedValue = sessionStorage.getItem("userid");

      

        var params = {
            TableName: 'staff_cfg',
            Key: {
                "id": Number.parseInt(tutorID)
            },
            UpdateExpression: 'set modifiedby = :modifiedby, #firstname = :firstname, #surname = :surname, #title = :title, #type = :type, #loginusername = :loginusername, #temporarypassword = :temporarypassword',
            ExpressionAttributeValues: {
                ':modifiedby': Number.parseInt(createdOrModifiedValue),
                ':firstname': document.getElementById('txtFirstName').value,
                ':surname': document.getElementById('txtSurName').value,
                ':title': document.getElementById('titles').value,
                ':type': document.getElementById('types').value,
                ':loginusername': document.getElementById('txtLoginUser').value,
                ':temporarypassword': document.getElementById('txtLoginPassword').value,
            },
            ExpressionAttributeNames:{
                "#firstname": "firstname",
                "#surname": "surname",
                "#title": "title",
                "#type": "type",
                "#loginusername": "loginusername",
                "#temporarypassword": "temporarypassword",
              }
        };


        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken')
            },
            body: JSON.stringify(params)
        };
        const response = await fetch(configData.api_url + "/tms/api/tutors", requestOptions);

        if (response.status == 403) {
            navigate('/');
        }

        if (response.status == 200) {
            toast.success("Updated Successfully");
            clearControls();
            navigate('/tutors');
        } else {
            toast.error(response.status + '-' + response.statusText);
        }
    }

    function cancelStudent() {
        navigate('/tutors')
    }

   

    return (
        <Container>

            <Row>
                <Col>
                    <Navbar>
                    </Navbar>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col><h2>{tutorID == undefined ? 'New Staff' : 'Staff Edit'}</h2></Col>
            </Row>


            <Row className='mt-2'>
            <Col sm={1}>
                    <div>
                        <span>Title</span>
                    </div>
                    <div>
                    <select name="titles" id="titles">
                    <option value="NA"></option>
                    <option selected ={(tutorArray != null && tutorArray.length > 0 
                    &&  tutorArray[0].title['S'] == "Mr")?true:false}  value="Mr">Mr</option>
                    <option selected ={tutorArray != null && tutorArray.length > 0 
                    &&  tutorArray[0].title['S'] == "Mrs" ? true : false } value="Mrs">Mrs</option>
                    <option selected ={tutorArray != null && tutorArray.length > 0 
                    &&  tutorArray[0].title['S'] == "Miss" ? true : false } value="Miss">Miss</option>
                   
                    </select>
                    </div>
</Col>

                <Col sm={4}>
                    <div>
                        <span>First Name</span>
                    </div>
                    <div>
                        <input maxlength="250" type='text' defaultValue={tutorArray != null && tutorArray.length > 0 ?
                            tutorArray[0].firstname['S'] : ''

                        } className='w-100' id='txtFirstName' />
                    </div>
</Col>
<Col sm={4}>
                    <div>
                        <span>Sur Name</span>
                    </div>
                    <div>
                        <input  maxlength="250" type='text' defaultValue={tutorArray != null && tutorArray.length > 0 ?
                            tutorArray[0].surname['S'] : ''

                        } className='w-100' id='txtSurName' />
                    </div>
</Col>
            </Row>

<Row>
    <Col sm={1}>
    <div>
                        <span>Type</span>
                    </div>
                    <div>
                    <select name="titles" id="types">
                    <option value="NA"></option>
                    <option selected ={(tutorArray != null && tutorArray.length > 0 
                    &&  tutorArray[0].type['S'] == "TUTOR")?true:false}  value="TUTOR">TUTOR</option>
                    <option selected ={tutorArray != null && tutorArray.length > 0 
                    &&  tutorArray[0].type['S'] == "ADMIN" ? true : false } value="ADMIN">ADMIN</option>
                    </select>
                    </div>
    </Col>
    <Col sm={4}>
    <div>
                        <span>Login Username</span>
                    </div>
                    <div>
                    <input type='text' maxlength="50" defaultValue={tutorArray != null && tutorArray.length > 0 
                    &&  tutorArray[0].loginusername != undefined
                    ?
                            tutorArray[0].loginusername['S'] : ''

                        } className='w-100' id='txtLoginUser'></input>
                    </div>
    </Col>
    <Col sm={4}>
    <div>
                        <span>Temporary Password</span>
                    </div>
                    <div>
                    <input type='text' maxlength="50" defaultValue={tutorArray != null && tutorArray.length > 0 
                     &&  tutorArray[0].temporarypassword != undefined
                    ?
                            tutorArray[0].temporarypassword['S'] : ''

                        } className='w-100' id='txtLoginPassword'></input>
                    </div>
    </Col>
</Row>

            <Row className='mt-3'>
                <Col className='d-flex'>
                    <button type="button" onClick={tutorArray == undefined ? post : put} className="btn btn-primary">{tutorID == undefined ? 'Save' : 'Update'}</button>&nbsp;
                    <button type="button" onClick={cancelStudent} className="btn btn-primary">{tutorID == undefined ? 'Back' : 'Cancel'}</button>
                </Col>
            </Row>

        </Container>

    );
}

export default ReactComponent;