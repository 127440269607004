import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import exportFromJSON from 'export-from-json'
import configData from "../../configuration/config.json";
import Navbar from '../../../src/components/navbar/index';
import { useNavigate } from 'react-router-dom';

function ReactComponent() {
    const navigate = useNavigate();
    const [yearsArray, setYearsArray] = useState(null);
    const [subjectArray, setSubjectArray] = useState(null);
    const [tutorArray, setTutorArray] = useState(null);
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [studentsArray, setStudentsArray] = useState(null);
    const [existingDataArray, setExistingDataArray] = useState(null);
    var searchFlg = false;

    useEffect(() => {
        getTutors();
        searchFlg = false;
    }, []);


  

    async function getEnteredlists() {

        searchFlg = true;

        if (document.getElementById('ddlWeek').value == '0'
           
        ) {
            toast.error("Week number is mandatory");
            return;
        }

        showLoader();

        let weekStartDateNumber = document.getElementById('ddlWeek').value;
        let weekNumber = document.getElementById('ddlWeek').options[document.getElementById('ddlWeek').selectedIndex].text;
        
        let tutorId = document.getElementById('ddltutor').value;
        
        const requestOptions = {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken') },
        };


        let existingAttendanceResponse = await fetch(configData.api_url + "/tms/api/tutors-reports/weekly-report?"
            + 'fromdate=' + weekStartDateNumber + '&'
            + 'weeknumber=' + weekNumber + '&'
            + 'tutorid=' + tutorId
            , requestOptions);

        if (existingAttendanceResponse.status == 403) {
            navigate('/');
        }


        let existingJson = await existingAttendanceResponse.json();


        if (existingJson.length == 0) {
            closeLoader();
            toast.error("No records found");
            return;
        }

        exportFromJSON({ data: existingJson, filename: 'WeeklyReport', exportType: 'xls' });
            

        closeLoader();
    }


    async function getEnteredlistsShow() {

        searchFlg = true;

        if (document.getElementById('ddlWeek').value == '0'
           
        ) {
            toast.error("Week number is mandatory");
            return;
        }

        showLoader();

        let weekStartDateNumber = document.getElementById('ddlWeek').value;
        let weekNumber = document.getElementById('ddlWeek').options[document.getElementById('ddlWeek').selectedIndex].text;
        
        let tutorId = document.getElementById('ddltutor').value;
        
        const requestOptions = {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem('tmsjwttoken') },
        };


        let existingAttendanceResponse = await fetch(configData.api_url + "/tms/api/tutors-reports/weekly-report?"
            + 'fromdate=' + weekStartDateNumber + '&'
            + 'weeknumber=' + weekNumber + '&'
            + 'tutorid=' + tutorId
            , requestOptions);

        if (existingAttendanceResponse.status == 403) {
            navigate('/');
        }


        let existingJson = await existingAttendanceResponse.json();

        
        if (existingJson.length == 0) {
            closeLoader();
            setExistingDataArray(null);
            toast.error("No records found");
            return;
        }

        setExistingDataArray(existingJson);

        closeLoader();
    }



    function showLoader() {
        document.getElementById('linearloaderdiv').style.display = 'block';
    }

    function closeLoader() {
        document.getElementById('linearloaderdiv').style.display = 'none';
    }

    const getSundayFromWeekNum = (weekNum, year) => {
        const sunday = new Date(year, 0, (1 + (weekNum - 1) * 7));
        while (sunday.getDay() !== 0) {
            sunday.setDate(sunday.getDate() - 1);
        }
        return sunday;
      }

      function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
      }

      function formatDate(date) {
        return (
          [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
          ].join('-') 
        );
      }


      async function getTutors() {

        

        const requestOptions = {
            headers: { 'Authorization': 'Bearer '+ sessionStorage.getItem('tmsjwttoken') },
        };

        let res = await fetch(configData.api_url+"/tms/api/staffs", requestOptions);

        if(res.status == 403){
            navigate('/');
        }

        let json = await res.json();
        setTutorArray(json.Items);
    }

    function clearAll(){
        setExistingDataArray(null);
        document.getElementById('ddltutor').value = '0';
        searchFlg = false;
    }

    return (

        <Container>
            <Row>
                <Col>
                    <Navbar></Navbar>
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col><h2>Tutor Report - By Week</h2></Col>
            </Row>

            <Row>
                <Col sm={4}>
                    <div><span>Week Number</span></div>
                    <div><select id="ddlWeek" name="weeks" className='w-100'>
                    <option value="0"></option>
                    <option value="1693785600000">1</option>
<option value="1694390400000">2</option>
<option value="1694995200000">3</option>
<option value="1695600000000">4</option>
<option value="1696204800000">5</option>
<option value="1696809600000">6</option>
<option value="1697414400000">7</option>
<option value="1698019200000">8</option>
<option value="1698624000000">9</option>
<option value="1699228800000">10</option>
<option value="1699833600000">11</option>
<option value="1700438400000">12</option>
<option value="1701043200000">13</option>
<option value="1701648000000">14</option>
<option value="1702252800000">15</option>
<option value="1702857600000">16</option>
<option value="1703462400000">17</option>
<option value="1704067200000">18</option>
<option value="1704672000000">19</option>
<option value="1705276800000">20</option>
<option value="1705881600000">21</option>
<option value="1706486400000">22</option>
<option value="1707091200000">23</option>
<option value="1707696000000">24</option>
<option value="1708300800000">25</option>
<option value="1708905600000">26</option>
<option value="1709510400000">27</option>
<option value="1710115200000">28</option>
<option value="1710720000000">29</option>
<option value="1711324800000">30</option>
<option value="1711929600000">31</option>
<option value="1712534400000">32</option>
<option value="1713139200000">33</option>
<option value="1713744000000">34</option>
<option value="1714348800000">35</option>
<option value="1714953600000">36</option>
<option value="1715558400000">37</option>
<option value="1716163200000">38</option>
<option value="1716768000000">39</option>
<option value="1717372800000">40</option>
<option value="1717977600000">41</option>
<option value="1718582400000">42</option>
<option value="1719187200000">43</option>
<option value="1719792000000">44</option>
<option value="1720396800000">45</option>
<option value="1721001600000">46</option>
<option value="1721606400000">47</option>
<option value="1722211200000">48</option>
<option value="1722816000000">49</option>
<option value="1723420800000">50</option>
<option value="1724025600000">51</option>
<option value="1724630400000">52</option>


</select></div>
                </Col>
                <Col sm={4}>
<div>
                        <span>Tutor</span>
                    </div>
                    <div>
                    <select className='w-100' id='ddltutor'>
                    <option key='0' value='0'></option>
                    {tutorArray != undefined && tutorArray.map((arrayItem) => <option key={arrayItem.id['N']} value={arrayItem.id['N']}>{arrayItem.title['S']}.&nbsp;{arrayItem.firstname['S']}&nbsp;{arrayItem.surname['S']}</option>)}
                </select>
                    </div>
    </Col>

            </Row>

            <Row className='mt-2'>
                <Col sm>
                    <button type="button" onClick={getEnteredlistsShow} className="btn btn-primary">Show</button>&nbsp;
                    <button type="button" onClick={getEnteredlists} className="btn btn-primary">Export</button>&nbsp;
                    <button type="button" onClick={clearAll} className="btn btn-primary">Clear</button>
                </Col>
            </Row>

            <Row className='mt-12'>

                <Col sm>

                {
                                existingDataArray!=undefined?
                                <>
                                {

<Row className='mt-2'>
<Col sm>

    <table className="table">
        <thead>
            <tr  class="table-light">
                <th scope="col">#</th>
                <th scope="col">First Name</th>
                <th scope="col">Sur Name</th>
                <th scope="col">Week Start</th>
                <th scope="col">Week#</th>
                <th scope="col">Subject</th>
                <th scope="col">Tutor</th>
                <th scope="col">Year</th>
                <th scope="col">Mon</th>
                <th scope="col">Tue</th>
                <th scope="col">Wed</th>
                <th scope="col">Thu</th>
                <th scope="col">Fri</th>
                <th scope="col">Sat</th>
                <th scope="col">Sun</th>
                <th scope="col">Total</th>
                <th scope="col">Fees</th>
            </tr>
        </thead>
        <tbody>

            {
                existingDataArray!=undefined &&

                existingDataArray.map((arrayItem, index) => 
                    <>
                        {
                    <tr className={arrayItem.Tutor != 'Total' ? '' : 'totalBgc'}>
                        <th scope="row">{arrayItem.WeekNumber != '' ? index+1 : ''}</th>
                        <td>{arrayItem.FirstName}</td>
                        <td>{arrayItem.SurName}</td>
                        <td>{arrayItem.WeekStartDate}</td>
                        <td>{arrayItem.WeekNumber}</td>
                        <td>{arrayItem.Subject}</td>

                        {arrayItem.WeekNumber != '' ?
                        <td>{arrayItem.Tutor}</td>
                         : 
                         <td><b>{arrayItem.Tutor}</b> </td>}
                        <td>{arrayItem.Year}</td>
                        <td>{arrayItem.Mon}</td>
                        <td>{arrayItem.Tue}</td>
                        <td>{arrayItem.Wed}</td>
                        <td>{arrayItem.Thu}</td>
                        <td>{arrayItem.Fri}</td>
                        <td>{arrayItem.Sat}</td>
                        <td>{arrayItem.Sun}</td>
                        {arrayItem.Tutor != 'Total' ?
                        <td>{arrayItem.Total}</td>
                         : 
                         <td><b>{arrayItem.Total}</b><span>&nbsp;hr</span></td>}

                        {arrayItem.WeekNumber != '' ?
                        <td><span>&#163;</span>
                        {arrayItem.Fees}</td>
                         : 
                         <td><b><span>&#163;</span>
                         {arrayItem.Fees}</b></td>}


                        
                    </tr>

                        }
                    </>
               
                   

                    
                    )

            }

           
           
          
        </tbody>
    </table>

</Col>
</Row>

                                }  
                                </> : ''
}

                </Col>    

            </Row>    
        </Container>


    );
}

export default ReactComponent;