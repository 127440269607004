import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


function CollapsibleExample() {


	let userType =  sessionStorage.getItem('type');
	let surname =  sessionStorage.getItem('surname');



	return (
	
		<div>

			{

				userType == 'TUTOR'? 
				
				<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
		<Container>
		  <Navbar.Brand href="#home">TMS</Navbar.Brand>
		  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
		  <Navbar.Collapse id="responsive-navbar-nav">
			<Nav className="me-auto">
			  <Nav.Link href="/attendance">Attendance</Nav.Link>
			  <Nav.Link href="/weekly-checklist">Weekly Checklist</Nav.Link>
			</Nav>
			<Nav>
			<Nav.Link href="/change-password">Change Password</Nav.Link>
			  <Nav.Link href="/">Logout</Nav.Link>
			  <div id='linearloaderdiv' style={{display:'none'}} className="linearloader" ></div>
			</Nav>
		  </Navbar.Collapse>
		</Container>
	  </Navbar>
				
				: 

				<Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
		<Container>
		  <Navbar.Brand href="#home">TMS</Navbar.Brand>
		  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
		  <Navbar.Collapse id="responsive-navbar-nav">
			<Nav className="me-auto">
			  <Nav.Link href="/attendance">Attendance</Nav.Link>
			  <Nav.Link href="/weekly-checklist">Weekly Checklist</Nav.Link>
			  <NavDropdown title="Settings" id="collasible-nav-dropdown">
				<NavDropdown.Item href="/subjects">Subjects</NavDropdown.Item>
				<NavDropdown.Item href="/students">Students</NavDropdown.Item>
				<NavDropdown.Item href="/fees">Fees</NavDropdown.Item>
				<NavDropdown.Item href="/tutors">Staffs</NavDropdown.Item>
			  </NavDropdown>
			  <NavDropdown title="Reports" id="collasible-nav-dropdown">
			  <NavDropdown.Item href="/students-reports-by-week">Students Reports By Week</NavDropdown.Item>
			  <NavDropdown.Item href="/tutors-reports-by-week">Tutors Reports By Week</NavDropdown.Item>
			  </NavDropdown>
			</Nav>
			<Nav>
			<Nav.Link href="/change-password">Change Password</Nav.Link>
			<Nav.Link href="/">Logout</Nav.Link>
		
			</Nav>
		  </Navbar.Collapse>
		</Container>
	  </Navbar>

			}
	  <div id='linearloaderdiv' style={{display:'none'}} className="linearloader" ></div>
<div><span>Welcome {surname}! </span></div>
		</div>

	);

	
	
  }
  
  export default CollapsibleExample;
